import React from 'react'
import Layout from "../components/Layout"
import Agb from "../components/Agb"
import {SEO} from "../components/Seo";
import {Helmet} from "react-helmet";

const termsConditions = () => {
    return (
        <Layout>
            <Helmet>
                <title>Terms and Conditions | Globale Trade Services | SAP-Knowledge like an espresso (books & e-books)</title>
            </Helmet>
            <Agb/>
        </Layout>
    )
}

export default termsConditions

export const Head = () => (
    <SEO/>
)
