import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import BookItem from "./blog/BookItem";

const Agb = () => {

    const books = useStaticQuery(graphql`
    {
        allMarkdownRemark {
        nodes {
            frontmatter {
                title
                tag
                slug
                excerpt
                link
                price
                pages
                claim
                features
                language
                image {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                }
            }
            html
            }
        }
    }
    `)

    const allBooks = books.allMarkdownRemark.nodes.map((item, index) => (
        <BookItem
            key={index}
            slug={item.frontmatter.slug}
            alt={item.frontmatter.title}
            title={item.frontmatter.title}
            tag={item.frontmatter.tag}
            link={item.frontmatter.link}
            excerpt={item.frontmatter.excerpt}
            image={item.frontmatter.image}
            price={item.frontmatter.price}
            claim={item.frontmatter.claim}
            features={item.frontmatter.features}
            pages={item.frontmatter.pages}
            language={item.frontmatter.language}
        />
    ))

    return (
        <div>
            <section className="bg-blue-200 text-gray-800 pt-16 pb-10 text-left">
                <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0">
                    <h1 className="font-semibold md:text-4xl text-2xl pb-4">Terms and Conditions</h1>
                    <p className="lg:text-2xl sm:text-lg text-sm">Espresso Tutorials GmbH</p>
                </div>
            </section>
            <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0 flex flex-row flex-wrap py-10">
                <div className="w-full md:w-content md:pr-16 lg:pr-24">
                    <div className="w-full mt-9 article">
                        <div className="prose lg:prose-xl">
                            <p>Please read our terms and conditions carefully before using our website. Proceeding to use the website will indicate your acceptance of the following terms and conditions. If you wish to use any services produced online now or in the future, you are also subject to the applicable terms. We reserve the right to change the website and these terms and conditions from time to time without notice to you, and your continued use of the website indicates your continued acceptance of the modified terms and conditions.</p>
                            <p><b>Communications with Website Users</b><br/>
                                Cookies: Your Web browser may allow you to be notified when you are receiving a cookie, giving you the choice to accept it or not. By not accepting cookies, some pages may not fully function and you may not be able to access certain information on this site.</p>
                            <p><b>Privacy policy</b><br/>
                                Customer information is collected for the purpose of processing your order. This information is kept confidential and is not shared.</p>
                            <p><b>Copyright Notice</b><br/>
                                This website and the information contained within it is protected by copyright. Reproduction of part or all of the website contents in any form is prohibited unless for personal use. Content of this website may not be copied to any other publication (including electronic, hard copy or others). Interior deep linking or framing of this website is prohibited without written permission of espresso-tutorialsg.com.</p>
                            <p><b>Disclaimer of Warranty; Limitation of Liability</b><br/>
                                YOU EXPRESSLY AGREE THAT USE OF THE COMMUNITY AREAS AND espresso-tutorials.com SITES IS AT YOUR SOLE RISK. NEITHER espresso-tutorials.com, ITS AFFILIATES NOR ANY OF THEIR RESPECTIVE EMPLOYEES, AGENTS, THIRD PARTY CONTENT PROVIDERS OR LICENSORS WARRANT THAT THE espresso-tutorials.com SITES WILL BE UNINTERRUPTED OR ERROR FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE espresso-tutorials.com SITES OR AS TO THE ACCURACY, RELIABILITY OR CONTENT OF ANY INFORMATION, SERVICE OR PRODUCTS PROVIDED THROUGH THE SITES OR THE COMMUNITY AREAS.</p>
                            <p><b>Termination</b><br/>
                                espresso-tutorials.com shall have the right to immediately terminate this Agreement with respect to any user which espresso-tutorials.com, at its sole discretion, considers to be unacceptable, or in the event of any breach by you of this Agreement. espresso-tutorials.com does not have to provide a refund for services under any conditions. Either you or espresso-tutorials.com may terminate this Agreement at any time. Your only right with respect to any dissatisfaction with (i) any terms and conditions of this Agreement, or policy or practice of espresso-tutorials.com in operating the espresso-tutorials.com Sites and/or the Community Areas, (ii) content available through the Subscription or change therein, or (iii) amount or type of fees or billing methods, or change therein, is to terminate this Agreement by accessing your account. Notice of termination will be effective upon receipt by espresso-tutorials.com Without limiting the foregoing, espresso-tutorials.com shall have the right to immediately terminate this Agreement with respect to any user which espresso-tutorials.com, in its sole discretion, considers to be unacceptable, or in the event of any breach by you of this Agreement. In the event that your account is terminated or canceled, no refund of any fees, including monthly membership fee, will be granted. Fees paid for any Subscription are paid in advance and are not refundable in whole or in part.</p>
                            <p><b>Subscription Services</b><br/>
                                The Subscription will continue until you cancel it by sending an email 30 days in advance of the expiration to alice.adams@espresso-tutorials.com.<br/>
                                You authorize espresso-tutorials.com, (via Stripe / Braintree), to charge to your account designated during the registration process the current fees and charges for each term according to the subscription plan. You may cancel the subscription and avoid future charges to your account by going to your account and following the directions listed. You are responsible for any charges associated to connecting to the espresso-tutorials.com Sites, including but not limited to, any telephone line charges or any Internet access provider charges. You shall provide espresso-tutorials.com with accurate, complete and updated information as to your name and e-mail address and account information provided by you at registration. Failure to do so shall constitute a breach of this Agreement.</p>
                            <p><b>Changed Terms</b><br/>
                                espresso-tutorials.com shall have the right at any time to impose, change or modify its fees and billing methods, or other terms and conditions applicable to your use of the Subscription or to impose new terms and conditions. Such changes, modifications, additions or deletions shall be effective immediately upon notice thereof, which may be given by means including, but not limited to, posting on the espresso-tutorials.com Sites a revised version of this Agreement or notification by electronic or conventional mail. If any such change is unacceptable to you, you may terminate your Subscription. Any use of the Subscription by you after such notice shall conclusively be deemed to constitute acceptance by you of such changes, modifications, additions or deletions. You agree to review the terms and conditions periodically to be aware of such revisions.</p>
                            <p><b>Refunds &amp; Fees</b><br/>
                                espresso-tutorials.com does NOT provide refunds to customers. All services are accurately represented and explained within the espresso-tutorials.com website. It is the sole responsibility of the subscriber to read and understand what services are being offered prior to joining. The fee is based on a yearly subscription and will be billed every year that you remain a member. This is an ongoing fee, not one time.</p>
                            <p><b>Recommended Merchants</b><br/>
                                espresso-tutorials.com does not guarantee any results nor hold any responsibility for the actions of any recommended merchants. This includes, but is not limited to, on time payment of funds, uptime of recommended merchant website nor any other technical or non-technical reason. espresso-tutorials.com also has the right to change and or remove recommended merchants from it is site at any time for any reason without any repercussions from members. Members of espresso-tutorials.com are strictly forbidden to deal with any and all past or present recommended merchants via private deals. Any member caught violating this will be terminated from the espresso-tutorials.com system immediately.</p>
                            <p><b>Miscellaneous</b><br/>
                                This Agreement and any operating rules for the espresso-tutorials.com Sites constitute the entire agreement of the parties with respect to the subject matter hereof, and supersede all previous written or oral agreements between the parties with respect to such subject matter. This Agreement shall be construed in accordance with the laws of Germany, without regard to its conflict of laws rules. No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. The section headings used herein are for convenience only and shall not be given any legal import.</p>
                        </div>
                    </div>
                </div>
                <div className="w-full w-sidebar mt-12 md:mt-0">
                    <h2 className="font-bold text-gray-800 text-2xl">Unsere Bücher</h2>
                    <div className="mt-8">
                        {allBooks}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Agb
